const IBLOCDefault: any[] = [
  {
    Year: 3,
    Age: 42,
    BaseContractPremium: 0,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 0,
    TotalCashValue: 248659,
    IncreaseInTotalCashValue: 0,
    TotalDeathBenefit: 10265337,
    Dividend: 9495,
    DividendTotalCashValue: 258154,
    DividendIncreaseInTotalCashValue: 0,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10274832,
  },
  {
    Year: 4,
    Age: 43,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 0,
    TotalPremium: 63816,
    TotalCashValue: 307462,
    IncreaseInTotalCashValue: 58803,
    TotalDeathBenefit: 10265337,
    Dividend: 13306,
    DividendTotalCashValue: 331195,
    DividendIncreaseInTotalCashValue: 73041,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10278643,
  },
  {
    Year: 5,
    Age: 44,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 172368,
    TotalPremium: 236184,
    TotalCashValue: 545105,
    IncreaseInTotalCashValue: 237643,
    TotalDeathBenefit: 10265337,
    Dividend: 17202,
    DividendTotalCashValue: 587460,
    DividendIncreaseInTotalCashValue: 256265,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10282539,
  },
  {
    Year: 6,
    Age: 45,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 700450,
    IncreaseInTotalCashValue: 155345,
    TotalDeathBenefit: 10265337,
    Dividend: 20325,
    DividendTotalCashValue: 765257,
    DividendIncreaseInTotalCashValue: 177797,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10285662,
  },
  {
    Year: 7,
    Age: 46,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 862508,
    IncreaseInTotalCashValue: 162058,
    TotalDeathBenefit: 10265337,
    Dividend: 23662,
    DividendTotalCashValue: 953966,
    DividendIncreaseInTotalCashValue: 188710,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10288999,
  },
  {
    Year: 8,
    Age: 47,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1031173,
    IncreaseInTotalCashValue: 168665,
    TotalDeathBenefit: 10265337,
    Dividend: 27393,
    DividendTotalCashValue: 1154082,
    DividendIncreaseInTotalCashValue: 200116,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10292730,
  },
  {
    Year: 9,
    Age: 48,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1206531,
    IncreaseInTotalCashValue: 175358,
    TotalDeathBenefit: 10265337,
    Dividend: 31281,
    DividendTotalCashValue: 1366014,
    DividendIncreaseInTotalCashValue: 211932,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10296618,
  },
  {
    Year: 10,
    Age: 49,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1388882,
    IncreaseInTotalCashValue: 182352,
    TotalDeathBenefit: 10265337,
    Dividend: 35447,
    DividendTotalCashValue: 1590573,
    DividendIncreaseInTotalCashValue: 224559,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10300784,
  },
  {
    Year: 11,
    Age: 50,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1576807,
    IncreaseInTotalCashValue: 187925,
    TotalDeathBenefit: 10265337,
    Dividend: 39922,
    DividendTotalCashValue: 1826876,
    DividendIncreaseInTotalCashValue: 236303,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10305259,
  },
  {
    Year: 12,
    Age: 51,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1771775,
    IncreaseInTotalCashValue: 194967,
    TotalDeathBenefit: 10265337,
    Dividend: 44797,
    DividendTotalCashValue: 2077049,
    DividendIncreaseInTotalCashValue: 250172,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10310134,
  },
  {
    Year: 13,
    Age: 52,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1973880,
    IncreaseInTotalCashValue: 202105,
    TotalDeathBenefit: 10265337,
    Dividend: 50138,
    DividendTotalCashValue: 2341985,
    DividendIncreaseInTotalCashValue: 264937,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10315520,
  },
  {
    Year: 14,
    Age: 53,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2183085,
    IncreaseInTotalCashValue: 209204,
    TotalDeathBenefit: 10265337,
    Dividend: 56322,
    DividendTotalCashValue: 2622022,
    DividendIncreaseInTotalCashValue: 280036,
    DeathBenefitWithoutDividends: 10739191,
    DividendTotalDeathBenefit: 10795513,
  },
  {
    Year: 15,
    Age: 54,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2399372,
    IncreaseInTotalCashValue: 216288,
    TotalDeathBenefit: 10265337,
    Dividend: 63293,
    DividendTotalCashValue: 2918005,
    DividendIncreaseInTotalCashValue: 295983,
    DeathBenefitWithoutDividends: 11296013,
    DividendTotalDeathBenefit: 11359306,
  },
  {
    Year: 16,
    Age: 55,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2618746,
    IncreaseInTotalCashValue: 219374,
    TotalDeathBenefit: 10265337,
    Dividend: 70432,
    DividendTotalCashValue: 3226243,
    DividendIncreaseInTotalCashValue: 308239,
    DeathBenefitWithoutDividends: 11857063,
    DividendTotalDeathBenefit: 11927495,
  },
  {
    Year: 17,
    Age: 56,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2845020,
    IncreaseInTotalCashValue: 226274,
    TotalDeathBenefit: 10467073,
    Dividend: 77956,
    DividendTotalCashValue: 3551551,
    DividendIncreaseInTotalCashValue: 325307,
    DeathBenefitWithoutDividends: 12422118,
    DividendTotalDeathBenefit: 12500073,
  },
  {
    Year: 18,
    Age: 57,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 3078292,
    IncreaseInTotalCashValue: 233273,
    TotalDeathBenefit: 10794118,
    Dividend: 85674,
    DividendTotalCashValue: 3894846,
    DividendIncreaseInTotalCashValue: 343296,
    DeathBenefitWithoutDividends: 12991626,
    DividendTotalDeathBenefit: 13077300,
  },
  {
    Year: 19,
    Age: 58,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 3318928,
    IncreaseInTotalCashValue: 240636,
    TotalDeathBenefit: 11110627,
    Dividend: 93538,
    DividendTotalCashValue: 4257020,
    DividendIncreaseInTotalCashValue: 362173,
    DeathBenefitWithoutDividends: 13565366,
    DividendTotalDeathBenefit: 13658904,
  },
  {
    Year: 20,
    Age: 59,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 3567177,
    IncreaseInTotalCashValue: 248249,
    TotalDeathBenefit: 11415934,
    Dividend: 101622,
    DividendTotalCashValue: 4638908,
    DividendIncreaseInTotalCashValue: 381888,
    DeathBenefitWithoutDividends: 14142946,
    DividendTotalDeathBenefit: 14244567,
  },
  {
    Year: 21,
    Age: 60,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 3714510,
    IncreaseInTotalCashValue: 147333,
    TotalDeathBenefit: 11415934,
    Dividend: 107925,
    DividendTotalCashValue: 4930593,
    DividendIncreaseInTotalCashValue: 291685,
    DeathBenefitWithoutDividends: 14428607,
    DividendTotalDeathBenefit: 14536533,
  },
  {
    Year: 22,
    Age: 61,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 3866127,
    IncreaseInTotalCashValue: 151617,
    TotalDeathBenefit: 11415934,
    Dividend: 114436,
    DividendTotalCashValue: 5237307,
    DividendIncreaseInTotalCashValue: 307190,
    DeathBenefitWithoutDividends: 14722017,
    DividendTotalDeathBenefit: 14836453,
  },
  {
    Year: 23,
    Age: 62,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4022004,
    IncreaseInTotalCashValue: 155877,
    TotalDeathBenefit: 11415934,
    Dividend: 121618,
    DividendTotalCashValue: 5561417,
    DividendIncreaseInTotalCashValue: 323634,
    DeathBenefitWithoutDividends: 15022948,
    DividendTotalDeathBenefit: 15144566,
  },
  {
    Year: 24,
    Age: 63,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4181964,
    IncreaseInTotalCashValue: 159960,
    TotalDeathBenefit: 11415934,
    Dividend: 129395,
    DividendTotalCashValue: 5902181,
    DividendIncreaseInTotalCashValue: 340764,
    DeathBenefitWithoutDividends: 15332357,
    DividendTotalDeathBenefit: 15461752,
  },
  {
    Year: 25,
    Age: 64,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4345929,
    IncreaseInTotalCashValue: 163966,
    TotalDeathBenefit: 11415934,
    Dividend: 137763,
    DividendTotalCashValue: 6260889,
    DividendIncreaseInTotalCashValue: 358718,
    DeathBenefitWithoutDividends: 15650912,
    DividendTotalDeathBenefit: 15788676,
  },
  {
    Year: 26,
    Age: 65,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4513910,
    IncreaseInTotalCashValue: 167981,
    TotalDeathBenefit: 11415934,
    Dividend: 146633,
    DividendTotalCashValue: 6638410,
    DividendIncreaseInTotalCashValue: 377511,
    DeathBenefitWithoutDividends: 15979194,
    DividendTotalDeathBenefit: 16125827,
  },
  {
    Year: 27,
    Age: 66,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4685911,
    IncreaseInTotalCashValue: 172001,
    TotalDeathBenefit: 11415934,
    Dividend: 155306,
    DividendTotalCashValue: 7034903,
    DividendIncreaseInTotalCashValue: 396493,
    DeathBenefitWithoutDividends: 16317501,
    DividendTotalDeathBenefit: 16472806,
  },
  {
    Year: 28,
    Age: 67,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4862106,
    IncreaseInTotalCashValue: 176195,
    TotalDeathBenefit: 11415934,
    Dividend: 164482,
    DividendTotalCashValue: 7451474,
    DividendIncreaseInTotalCashValue: 416570,
    DeathBenefitWithoutDividends: 16664515,
    DividendTotalDeathBenefit: 16828997,
  },
  {
    Year: 29,
    Age: 68,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5042565,
    IncreaseInTotalCashValue: 180459,
    TotalDeathBenefit: 11415934,
    Dividend: 174320,
    DividendTotalCashValue: 7889253,
    DividendIncreaseInTotalCashValue: 437779,
    DeathBenefitWithoutDividends: 17020530,
    DividendTotalDeathBenefit: 17194850,
  },
  {
    Year: 30,
    Age: 69,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5227383,
    IncreaseInTotalCashValue: 184819,
    TotalDeathBenefit: 11415934,
    Dividend: 184301,
    DividendTotalCashValue: 8348918,
    DividendIncreaseInTotalCashValue: 458666,
    DeathBenefitWithoutDividends: 17386121,
    DividendTotalDeathBenefit: 17570423,
  },
  {
    Year: 31,
    Age: 70,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5416451,
    IncreaseInTotalCashValue: 189067,
    TotalDeathBenefit: 11415934,
    Dividend: 195054,
    DividendTotalCashValue: 8838371,
    DividendIncreaseInTotalCashValue: 482553,
    DeathBenefitWithoutDividends: 17760730,
    DividendTotalDeathBenefit: 17955783,
  },
  {
    Year: 32,
    Age: 71,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5609543,
    IncreaseInTotalCashValue: 193093,
    TotalDeathBenefit: 11415934,
    Dividend: 206299,
    DividendTotalCashValue: 9337493,
    DividendIncreaseInTotalCashValue: 506022,
    DeathBenefitWithoutDividends: 18145074,
    DividendTotalDeathBenefit: 18351373,
  },
]
export const calculateIBLOCBalance = (cashIn: any, Q: any, cashOut: any, iblocBalance: any) => {
  if (cashIn > iblocBalance + cashOut) {
    return 0
  } else {
    return (1 + Q / 100) * (cashOut + iblocBalance - cashIn)
  }
}
export const IBLOCDefaultData = generateDataForIBLOCArray(IBLOCDefault)

export function generateDataForIBLOCArray(dataArray: any[]) {
  let givenRor = 7 / 100

  let tempArray = []
  for (let i = 0; i < dataArray.length; i++) {
    let tempObj = {...dataArray[i]}
    switch (true) {
      case i === 0:
        tempObj['CashIn'] = 0
        break
      case i === 1:
        tempObj['CashIn'] = 63816
        break
      case i === 2:
        tempObj['CashIn'] = 236184
        break
      case i >= 3 && i <= 17:
        tempObj['CashIn'] = 150000
        break
      default:
        tempObj['CashIn'] = 39316
        break
    }

    tempObj['APR'] = 6
    tempObj['CashOut'] = i === 0 ? 100000 : tempObj.TotalPremium
    tempObj['IBLOCBalance'] = calculateIBLOCBalance(
      tempObj.CashIn,
      tempObj.APR,
      tempObj.CashOut,
      i === 0 ? 0 : tempArray[i - 1].IBLOCBalance
    )
    tempObj['IBLOCBalanceforBar'] = tempObj.IBLOCBalance * -1
    tempObj['TotalCashValueCalculated'] = tempObj.DividendTotalCashValue
    tempObj['CashBalance'] =
      tempObj.TotalCashValueCalculated - tempObj.IBLOCBalance + tempObj.CashIn
    tempObj['CvGrowthYoY'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempArray[i - 1].TotalCashValueCalculated) /
            tempArray[i - 1].TotalCashValueCalculated) *
          100
    tempObj['TotalCvGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempArray[0].TotalCashValueCalculated) /
            tempArray[0].TotalCashValueCalculated) *
          100
    tempObj['CashBalanceGrowth'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[i - 1].CashBalance) / tempArray[i - 1].CashBalance) *
          100
    tempObj['CashBalanceGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[0].CashBalance) / tempArray[0].CashBalance) * 100
    tempObj['TotalCashPutInSoFar'] =
      i === 0 ? 0 : tempObj.CashIn + tempArray[i - 1].TotalCashPutInSoFar
    tempObj['CashOnCashROI'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempObj.TotalCashPutInSoFar) /
            tempObj.TotalCashPutInSoFar) *
          100
    tempObj['TotalCashOpportunity'] =
      i === 0 ? 0 : (tempObj.CashIn + tempArray[i - 1].TotalCashOpportunity) * (1 + givenRor)
    tempObj['TCOOROI'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashOpportunity - tempObj.TotalCashPutInSoFar) /
            tempObj.TotalCashPutInSoFar) *
          100
    tempObj['TCVDelta'] = i === 0 ? 0 : tempObj.TotalCashValue - tempArray[i - 1].TotalCashValue
    tempObj['CBDelta'] = i === 0 ? 0 : tempObj.CashBalance - tempArray[i - 1].CashBalance
    tempArray.push(tempObj) // Add the modified object to the tempArray
  }
  return tempArray
}
