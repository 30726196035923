import React, {useState, useEffect} from 'react'
import {Modal, Button, Form} from 'react-bootstrap'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  sblocClients: any[]
  alreadyRegisteredCompanies: string
  updateUserCompanies: (updatedCompanies: any) => void // Corrected syntax
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  sblocClients,
  alreadyRegisteredCompanies,
  updateUserCompanies,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  // Effect to update selectedItems based on alreadyRegisteredCompanies
  useEffect(() => {
    const namesArray = alreadyRegisteredCompanies
      .replace(/[\[\]"]/g, '') // Remove square brackets and quotes
      .split(',') // Split by comma

    // Now namesArray contains the names
    setSelectedItems(namesArray)
  }, [alreadyRegisteredCompanies]) // Re-run effect if `alreadyRegisteredCompanies` changes

 const handleCheckboxChange = (clientName: string) => {
   setSelectedItems((prevSelectedItems) => {
     let updatedSelectedItems

     if (prevSelectedItems.includes(clientName)) {
       // If already selected, remove it
       updatedSelectedItems = prevSelectedItems.filter((item: string) => item !== clientName)
     } else {
       // If not selected, add it
       updatedSelectedItems = [...prevSelectedItems, clientName]
     }
     // Return the updated array
     return updatedSelectedItems
   })
 }


  function flattenDeepArray(arr: string[]): string[] {
    const results: string[] = []

    function getFlattenArray(arr1: string[]): void {
      // Check if none of the array elements include a '['
      if (!arr1.some((i) => i.includes('['))) {
        results.push(...arr1)
        return
      }

      arr1.forEach((item) => {
        // If the item includes '[', attempt to parse it as an array
        if (item.includes('[')) {
          try {
            getFlattenArray(JSON.parse(item))
          } catch (e) {
            // If JSON.parse fails, log the error or handle it
            console.error('Parsing error:', e)
          }
        } else {
          results.push(item)
        }
      })
    }

    getFlattenArray(arr)
    return results
  }

  const handleSubmit = () => {
    const newData: string[] = Array.from(new Set(flattenDeepArray(selectedItems)))
    updateUserCompanies(newData)
    onClose() // Close the modal after submission
    setSelectedItems([]) // Optionally clear selected items on submit
  }

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-wrap justify-content-center'>
          {sblocClients?.map((item, index) => (
            <Form.Check
              key={index} // Ensure each element has a unique key
              type='checkbox'
              id={`checkbox-${index}`}
              label={item.clientName}
              checked={selectedItems.includes(item.clientName)}
              onChange={() => handleCheckboxChange(item.clientName)}
              className='m-2'
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomModal
