import {useEffect, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'

import {DataRow} from '../core/_model'
import DataTableSearch from './DataTableSearch'
import SendTokensModal from '../../../../modals/SendTokensmodal'
import {Dropdown} from 'react-bootstrap'
import {updateblockStatus} from '../core/_request'
import {toast} from 'react-toastify'
import CustomModal from './CustomModal'
import {updateUserCompanies} from '../core/_request'
import { useAuth } from '../../../../modules/auth'
interface Props {
  usersData: DataRow[]
  sblocClientsRow: any[]
}

const DataTableComponent: React.FC<Props> = ({usersData, sblocClientsRow}) => {
  const notifyError = (msg: string) => toast.error(msg)
  const {currentUser}=useAuth()
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [filteredData, setFilteredData] = useState<DataRow[]>(usersData)
  const [selectedUser, setSelectedUser] = useState<DataRow | null>(null)
  const [modalToAssignDeAssignCompany, setModalToAssignDeAssignCompany] = useState(false)
  const [registeredCompanies, setRegisteredCompanies] = useState('')
  const [currentUserId,setcurrentUserId]=useState(0)
  useEffect(() => {
    const result = usersData.filter((data) => {
      return data?.email?.toLowerCase()?.includes(search?.toLowerCase())
    })
    setFilteredData(result)
  }, [search])

  // handle modal
  const handleOpenModal = (user: DataRow) => {
    setSelectedUser(user)
    setShowModal(true)
  }

  // update filteredData array
  const updateTable = async (id: any, dailyLimit: any, availableTokens: any) => {
    setFilteredData((prevData) =>
      prevData.map((user) =>
        user.id === id
          ? {...user, daily_tokens_limit: dailyLimit, available_tokens: availableTokens}
          : user
      )
    )
  }

  // update filteredData array
  const updateBlockStatus = (id: number, blockStatus: boolean) => {
    setFilteredData((prevData) =>
      prevData.map((user) => (user.id === id ? {...user, is_blocked: blockStatus} : user))
    )
  }

  // handle block status
  const handleBlockStatus = async (id: number, blockStatus: boolean) => {
    try {
      const res = await updateblockStatus(id, blockStatus)
      if (res?.status === 200) {
        updateBlockStatus(id, blockStatus)
      }
    } catch (error) {
      notifyError('An error occurred during the request')
    }
  }
  // functions to open the assign deassign modal
  const openModal = () => setModalToAssignDeAssignCompany(true)
  const closeModal = () => setModalToAssignDeAssignCompany(false)

  const onModalOpen = (companies: string,userId:number) => {
    openModal()
    setRegisteredCompanies(companies)
    setcurrentUserId(userId)
  }
  const handleUpdatedCompanies = async(updatedCompanies: any) => {
  await  updateUserCompanies(updatedCompanies, currentUserId)
  }
  // data table columns
  const columns: TableColumn<DataRow>[] = [
    {
      name: 'Sr.#',
      id: 'id',
      cell: (row, index) => (index = index + 1),
    },
    {
      name: 'Name',
      id: 'name',
      selector: (row) => row.name,
    },
    {
      name: 'Email',
      id: 'email',
      width: '20%',
      cell: (row) => (
        <p className='m-0 paragraph-small text-truncate' title={row.email}>
          {row.email}
        </p>
      ),
    },
    {
      name: 'Daily Tokens Limit',
      id: 'daily_tokens_limit',
      selector: (row) => row.daily_tokens_limit?.toString(),
    },
    {
      name: 'Available Tokens',
      id: 'available_tokens',
      selector: (row) => row.available_tokens?.toString(),
    },
    {
      name: 'AI Queries',
      id: 'ai_queries',
      selector: (row) => row.ai_queries?.toString() ?? 0,
    },
    {
      name: 'AI Words',
      id: 'ai_words',
      selector: (row) => row.ai_words?.toString() ?? 0,
    },
    {
      name: 'SBLOC Actions',
      id: 'sbloc_actions',
      selector: (row) => row.sbloc_actions?.toString() ?? 0,
    },
    {
      name: 'Status',
      id: 'is_blocked',
      cell: (row) => (
        <div
          className={`${
            !row.is_blocked ? 'bg-success' : 'bg-danger'
          } m-0 paragraph-small p-2 text-white rounded-4`}
        >
          <p className='m-0 paragraph-small text-white'>
            {!row.is_blocked ? 'Approved' : 'Blocked'}
          </p>
        </div>
      ),
    },
    {
      name: 'Action',
      id: 'action',
      cell: (row) => (
        <>
          <Dropdown>
            <Dropdown.Toggle variant='' id='' split className='m-0'>
              &nbsp;
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={row.is_blocked}
                onClick={() => handleBlockStatus(row?.id, true)}
              >
                Block
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!row.is_blocked}
                onClick={() => handleBlockStatus(row?.id, false)}
              >
                Approve
              </Dropdown.Item>
              {currentUser?.user_role === 0 && (
                <Dropdown.Item onClick={() => onModalOpen(row.registered_companies, row.id)}>
                  Assign/DeAssign Company
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </>
      ),
    },
  ]

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        fixedHeader
        highlightOnHover
        subHeader
        subHeaderComponent={<DataTableSearch value={search} setSearch={setSearch} />}
        onRowClicked={handleOpenModal}
      />
      <SendTokensModal
        user={selectedUser}
        show={showModal}
        onHide={() => setShowModal(false)}
        updateTable={updateTable}
      />
      <CustomModal
        isOpen={modalToAssignDeAssignCompany}
        onClose={closeModal}
        sblocClients={sblocClientsRow}
        alreadyRegisteredCompanies={registeredCompanies}
        updateUserCompanies={handleUpdatedCompanies}
      />
    </>
  )
}

export default DataTableComponent
