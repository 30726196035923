import MetaTags from '../../../Meta/MetaTags'
import Chatbot from '../../modules/chatbot/Chatbot'
import {IBLOCIframe} from '../../modules/components/IBLOCIframe'
import {useEffect} from 'react'

const IBLOCChatBotPage = () => {
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]
  let isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => window.location.search.includes(path) || window.location.pathname.slice(1) === path
  )

  
  useEffect(() => {
    const iframe = document.getElementById('myIBLOCIframe') as HTMLIFrameElement | null
    if (iframe) {
      iframe.onload = function () {
        const iframeDoc = iframe.contentWindow?.document
        if (iframeDoc) {
          const css = `
            /* Hide scrollbar for Chrome, Safari, and Opera */
            body::-webkit-scrollbar {
              display: none;
            }
            /* Hide scrollbar for IE, Edge, and Firefox */
            body {
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;  /* Firefox */
            }
          `
          const style = document.createElement('style')
          style.type = 'text/css'
          style.appendChild(document.createTextNode(css))
          iframeDoc.head.appendChild(style)
        }
      }
    }
  }, [])

  return (
    <>
      <MetaTags URL={window.location.href} />

      <div className='row m-0'>
        <div className='col-8 position-fixed' style={{height: '100%', border: 'none'}}>
          <IBLOCIframe id={'myIBLOCIframe'} />
        </div>
        {/* popping out chatbot for a while */}
        <div
          className='col-4 position-absolute'
          style={{
            right: 0,
            backgroundColor: isWayStoneStyledInUrl ? '#d0cfcd' : '',
            // transform: 'scale(0.8)',
          }}
        >
          <Chatbot isWayStoneStyled={isWayStoneStyledInUrl} />
        </div>
      </div>
    </>
  )
}

export {IBLOCChatBotPage}
