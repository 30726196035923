import MetaTags from '../../../Meta/MetaTags'
import { IBLOCIframe } from '../../modules/components/IBLOCIframe'

const IBLOCPage = () => {
  return (
    <>
      <MetaTags URL={window.location.href} />
      <div style={{height: '90vh'}}>
        <IBLOCIframe id={'IBLOCIFRAME'} />
      </div>
    </>
  )
}

export {IBLOCPage}
