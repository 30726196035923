import {useFormik} from 'formik'
import * as Yup from 'yup'
import {IPersonalizationForm} from '../core/_model'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import {setYearsToGrow, useChatbotContext} from '../../../context/ChatbotContext'
import {useAuth} from '../../../../modules/auth'
export const usePersonalizationFormState = () => {
  const navigate = useNavigate()
  const {currentUser, logout} = useAuth()
  const {setSidePanelPreferencesObj} = useChatbotContext()

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')
  const parsedPersonalizationFormObj = personalizationFormObj
    ? JSON.parse(personalizationFormObj)
    : {}

  // define the validation schema using Yup
  const ComparePerformanceSchema = Yup.object().shape({
    investment_goal: Yup.string(),
    risk_tolerance: Yup.string(),
    investment_experience: Yup.string(),
    time_horizon: Yup.string(),
    liquidity_needs: Yup.string(),
    income_needs: Yup.string(),
    tech_comfort: Yup.string(),
    initial_investment_amount: Yup.string(),
    contributions: Yup.string(),
    contributions_frequency: Yup.string(),
    initial_deposit_capability: Yup.string(),
    asset_preference: Yup.string(),
  })

  // define the initial form values
  const initialValues: IPersonalizationForm = personalizationFormObj
    ? {
        investment_goal: parsedPersonalizationFormObj.investment_goal,
        risk_tolerance: parsedPersonalizationFormObj.risk_tolerance,
        investment_experience: parsedPersonalizationFormObj.investment_experience,
        time_horizon: parsedPersonalizationFormObj.time_horizon,
        liquidity_needs: parsedPersonalizationFormObj.liquidity_needs,
        income_needs: parsedPersonalizationFormObj.income_needs,
        tech_comfort: parsedPersonalizationFormObj.tech_comfort,
        initial_investment_amount: parsedPersonalizationFormObj.initial_investment_amount,
        contributions: parsedPersonalizationFormObj.contributions,
        contributions_frequency: parsedPersonalizationFormObj.contributions_frequency,
        initial_deposit_capability: parsedPersonalizationFormObj.initial_deposit_capability,
        asset_preference: parsedPersonalizationFormObj.asset_preference,
      }
    : {
        investment_goal: '',
        risk_tolerance: '',
        investment_experience: '',
        time_horizon: '',
        liquidity_needs: '',
        income_needs: '',
        tech_comfort: '',
        initial_investment_amount: '',
        contributions: '',
        contributions_frequency: '',
        initial_deposit_capability: '',
        asset_preference: '',
      }

  //send data to spread sheet
  const sendToSheets = async (personalizationFormObj: IPersonalizationForm, date: string) => {
    // personalization form OBJ
    const encodedInvestmentGoal = encodeURIComponent(personalizationFormObj.investment_goal)
    const encodedRiskTolerance = encodeURIComponent(personalizationFormObj.risk_tolerance)
    const encodedInvestmentExperience = encodeURIComponent(
      personalizationFormObj.investment_experience
    )
    const encodedTimeHorizon = encodeURIComponent(personalizationFormObj.time_horizon)
    const encodedLiquidityNeeds = encodeURIComponent(personalizationFormObj.liquidity_needs)
    const encodedIncomeNeeds = encodeURIComponent(personalizationFormObj.income_needs)
    const encodedTechComfort = encodeURIComponent(personalizationFormObj.tech_comfort)
    const encodedInitialInvestmentAmount = encodeURIComponent(
      personalizationFormObj.initial_investment_amount
    )
    const encodedContributions = encodeURIComponent(personalizationFormObj.contributions)
    const encodedContributionsFrequency = encodeURIComponent(
      personalizationFormObj.contributions_frequency
    )
    const encodedinitialDepositCapability = encodeURIComponent(
      personalizationFormObj.initial_deposit_capability
    )
    const encodedAssetPreference = encodeURIComponent(personalizationFormObj.asset_preference)

    // additional parametersclear
    const current_date = encodeURIComponent(date)
    // Update the script URL to your Google Script URL
    const scriptUrl = `${
      process.env.REACT_APP_GOOGLE_SHEETS_DEPLOYMENT_URL
    }?investment_goal=${encodedInvestmentGoal}&risk_tolerance=${encodedRiskTolerance}&investment_experience=${encodedInvestmentExperience}&time_horizon=${encodedTimeHorizon}&liquidity_needs=${encodedLiquidityNeeds}&income_needs=${encodedIncomeNeeds}&tech_comfort=${encodedTechComfort}&encodedInitialInvestmentAmount=${encodedInitialInvestmentAmount}&contributions=${encodedContributions}&contributions_frequency=${encodedContributionsFrequency}&initial_deposit_capability=${encodedinitialDepositCapability}&asset_preference=${encodedAssetPreference}&current_date=${current_date}&is_personalization_logs=${true}`

    try {
      const response = await axios.get(scriptUrl)
      if (response.status === 200) {
        console.log('Message sent successfully. Row:', response.data)
      } else {
        console.error('Error sending message:', response.statusText)
      }
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  // formik handle submit
  const handleSubmit = async (values: IPersonalizationForm) => {
    // formatted date
    const date = moment().format('YYYY-MM-DD HH:mm:ss')

    // convert form values to a JSON string
    const personalizationFormOBJ = JSON.stringify(values)

    // store the JSON string in localStorage under the key 'personalizationFormObj'
    localStorage.setItem('personalizationFormObj', personalizationFormOBJ)

    // retrieve the item from localStorage to confirm it was stored
    const storedItem = localStorage.getItem('personalizationFormObj')

    // check if the stored item exists before navigating
    if (storedItem) {
      setSidePanelPreferencesObj({
        starting_amount: +values?.initial_investment_amount,
        contributions: +values?.contributions,
        years_to_grow: setYearsToGrow(values?.time_horizon),
        risk_tolerance: values?.risk_tolerance,
        preffered_assets: [],
      })
      navigate(`/${currentUser?.registered_companies[0]}`) // navigate to the '/chat-bot' route
      sendToSheets(values, date)
    } else {
      // optionally handle the case where the data wasn't successfully saved
      console.error('Failed to save form data to localStorage.')
    }
  }

  // formik
  const formik = useFormik<IPersonalizationForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: ComparePerformanceSchema,
    onSubmit: handleSubmit,
  })

  // handle change RoR/compounding growth
  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('contributions_frequency', value)
  }

  // scroll to personalization form on chevron-down click
  const scrollToForm = () => {
    // calculate 100vh position
    const height = window.innerHeight + 100

    // Scroll to 100vh position
    window.scrollTo({
      top: height,
      behavior: 'smooth',
    })
  }

  // scroll to personalization form on scroll
  const handleScroll = (e: WheelEvent) => {
    if (e.deltaY > 0) {
      // Scrolling down
      scrollToForm()
    }
    // else {
    //   // Scrolling up
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    //   })
    // }
  }

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, {passive: false})

    return () => {
      window.removeEventListener('wheel', handleScroll)
    }
  }, [])

  return {
    formik,
    scrollToForm,
    handleCompoundingFreqChange,
  }
}
