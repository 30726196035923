import React from 'react'
import {useParams, Navigate} from 'react-router-dom'

interface ValidCompanyRouteProps {
  component: React.ComponentType
  allowedCompanies: string[]
}

const ValidCompanyRoute: React.FC<ValidCompanyRouteProps> = ({
  component: Component,
  allowedCompanies,
}) => {
  const {companyName} = useParams<{companyName?: string}>()

  if (!companyName || !allowedCompanies.includes(companyName)) {
    return <Navigate to='/error/404' replace />
  }

  return <Component />
}

export default ValidCompanyRoute
