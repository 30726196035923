import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  IUpdatePassword,
  updatePassword,
} from '../../modules/profile/components/settings/SettingsModel'
import {useAuth} from '../../../modules/auth'
import {
  changePassword,
  updateUserFirstLoginStatus,
} from '../../modules/profile/components/settings/_request'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {getUserByToken} from '../../../modules/auth/core/_requests'

const passwordFormValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const ChangePassword: React.FC = () => {
  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')

  // setting toastify success/error
  const notifySuccess = (msg: string) => toast.success(msg)
  const notifyError = (msg: string) => toast.error(msg)

  const navigate = useNavigate()

  const {currentUser, setCurrentUser, saveAuth} = useAuth()

  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)
  const [loading, setLoading] = useState(false)

  const formik = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      let res
      try {
        await changePassword(values.currentPassword, values.newPassword)
        const {data: auth} = await updateUserFirstLoginStatus(currentUser?.id)
        console.log('auth: ', auth)
        console.log('auth.auth_token: ', auth.auth_token)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.auth_token)
        setCurrentUser(user)
        resetForm({})
        setLoading(false)
        notifySuccess('Password changed successfully')
        navigate(!personalizationFormObj ? '/home' : `${currentUser?.registered_companies[0]}`)
      } catch (error: any) {
        notifyError((error as Error).message) // Log the error for debugging

        if (error.response) {
          notifyError(error.response.data.message) // Error message from the server
        } else {
          notifyError('Error occurred during the request.') // Error occurred without a response from the server
        }

        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='w-100 m-auto  d-flex justify-content-center align-items-center'>
      <div className='card mb-5 mb-xl-10 w-75'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_signin_method'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Update your password to continue</h3>
          </div>
        </div>
        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='separator separator-dashed my-6'></div>
            <div className='d-flex flex-wrap align-items-center mb-10'>
              <div id='kt_signin_password_edit' className='flex-row-fluid '>
                <form
                  onSubmit={formik.handleSubmit}
                  id='kt_signin_change_password'
                  className='form'
                  noValidate
                >
                  <div className='row mb-1'>
                    <div className='col-lg-4'>
                      <div className='fv-row mb-0'>
                        <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
                          Current Password
                        </label>
                        <input
                          type='password'
                          className='form-control form-control-lg form-control-solid border-dark'
                          id='currentpassword'
                          {...formik.getFieldProps('currentPassword')}
                        />
                        {formik.touched.currentPassword && formik.errors.currentPassword && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fv-row mb-0'>
                        <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                          New Password
                        </label>
                        <input
                          type='password'
                          className='form-control form-control-lg form-control-solid border-dark'
                          id='newpassword'
                          {...formik.getFieldProps('newPassword')}
                        />
                        {formik.touched.newPassword && formik.errors.newPassword && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.newPassword}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fv-row mb-0'>
                        <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                          Confirm New Password
                        </label>
                        <input
                          type='password'
                          className='form-control form-control-lg form-control-solid border-dark'
                          id='confirmpassword'
                          {...formik.getFieldProps('passwordConfirmation')}
                        />
                        {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {formik.errors.passwordConfirmation}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='form-text mb-5'>
                    Password must be at least 8 character and contain symbols
                  </div>

                  <div className='d-flex'>
                    <button
                      id='kt_password_submit'
                      type='submit'
                      className='primary-btn me-2 px-6 border-0'
                      style={{maxWidth: '14rem'}}
                    >
                      {!loading && 'Update Password'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChangePassword}
