import clsx from 'clsx'
import {FC, useState} from 'react'
import {Dropdown, Nav} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import {ThemeModeSwitcher} from '../../../../partials'
import {
  NAVBAR_CONSTANTS,
  CONSTANTS,
  FEATURE_COMING_SOON,
} from '../../../../../app/constants/AppConstants'
import {useJoinWaitlistContext} from '../../../../../app/user/context/JoinWaitlistContext'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../../app/modules/auth'
import VerticallyCenteredModal from './VerticallyCenteredModal'

const itemClass = 'ms-1 ms-lg-3'

const NavbarComponent: FC = () => {
  const navigate = useNavigate()
  const {currentUser, logout} = useAuth()

  const {setShowModal} = useJoinWaitlistContext()
  const [showThankYouModal, setShowThankYouModal] = useState(false)
  // Safely converting to an array if needed
let result: any
try {
  // Check if the input is already an array
  if (typeof currentUser?.registered_companies === 'string') {
    // Try to parse the string
    result = JSON.parse(currentUser?.registered_companies)
  } else if (Array.isArray(currentUser?.registered_companies)) {
    // If currentUser?.registered_companies is already an array, do nothing
    result = currentUser?.registered_companies
  } else {
    throw new Error('Invalid input format')
  }

} catch (error) {
  console.error('Error parsing or processing input:', error)
}
  return (
    <Navbar expand='lg' bg='light' data-bs-theme='primary' sticky='top'>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />

        <Navbar.Brand href='/'>
          <img alt='chloee-img' className='chloee-logo' src={CONSTANTS.IMAGES.LOGO} />
        </Navbar.Brand>

        <Navbar.Collapse id='basic-navbar-nav'>
          {/* <Nav className='me-auto'>
            <Nav.Link className='nav-link nav-text' href='#'>
              {NAVBAR_CONSTANTS.NAVLINK1}
            </Nav.Link>
            <Nav.Link className='nav-link nav-text' href='#'>
              {NAVBAR_CONSTANTS.NAVLINK2}
            </Nav.Link>
            <Nav.Link className='nav-link nav-text' href='#'>
              {NAVBAR_CONSTANTS.NAVLINK3}
            </Nav.Link>
          </Nav> */}

          <div className='ms-auto d-flex align-items-center py-2 py-lg-0'>
            {/* <div className={clsx('app-navbar-item', itemClass)}>
              <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
            </div> */}
            {/* <Nav>
              <Nav.Link className='nav-link nav-text' href='#'>
                {NAVBAR_CONSTANTS.NAVLINK4}
              </Nav.Link>
            </Nav> */}
            <a onClick={() => setShowThankYouModal(true)} target='_blank' rel='noopener noreferrer'>
              <i
                className='fab fa-reddit social-icons-header'
                style={{color: '#FF4500'}}
                aria-hidden='true'
              />
            </a>
            <a onClick={() => setShowThankYouModal(true)} target='_blank' rel='noopener noreferrer'>
              <i
                className='fab fa-instagram social-icons-header mx-5'
                style={{
                  background:
                    'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
                  color: 'transparent',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                }}
                aria-hidden='true'
              />
            </a>
            <a onClick={() => setShowThankYouModal(true)} target='_blank' rel='noopener noreferrer'>
              <i
                className='fab fa-tiktok social-icons-header'
                style={{color: '#000000'}}
                aria-hidden='true'
              />
            </a>
          </div>
          <div className='secondary-btn ms-4 mt-2 mt-lg-0' onClick={() => setShowModal(true)}>
            {NAVBAR_CONSTANTS.BUTTON}
          </div>
          <div>
            {currentUser && (
              <Dropdown className='ms-4'>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic' className='mt-0 bg-white'>
                  Advisor
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {result.map((company: string, index: number) => (
                    <Dropdown.Item
                      key={index} // It's better to use a unique id if available
                      onClick={(event) => {
                        event.preventDefault()
                        navigate(`/${company}`)
                      }}
                    >
                      {company}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>

          <div className='secondary-btn ms-4 mt-2 mt-lg-0' onClick={() => navigate('/home')}>
            {NAVBAR_CONSTANTS.EDIT_PREFERENCES_BTN}
          </div>
          {currentUser && (
            <div className='secondary-btn ms-4 mt-2 mt-lg-0' onClick={logout}>
              Logout
            </div>
          )}
        </Navbar.Collapse>
      </Container>
      <VerticallyCenteredModal
        show={showThankYouModal}
        onHide={() => setShowThankYouModal(false)}
        title={FEATURE_COMING_SOON.TITLE}
        message={FEATURE_COMING_SOON.MESSAGE}
      />
    </Navbar>
  )
}

export {NavbarComponent}
