import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

interface VerticallyCenteredModalProps {
  show: boolean
  onHide: () => void
  message: string
  title: string
}

const VerticallyCenteredModal: React.FC<VerticallyCenteredModalProps> = (props) => {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter' className='heading sub-heading-x-small'>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='paragraph paragraph-small'>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='secondary-btn' onClick={props.onHide}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default VerticallyCenteredModal
