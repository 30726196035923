import {useEffect} from 'react'
import {useChatbotContext} from '../../context/ChatbotContext'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

interface IframeProps {
  id: string
}

const SBLOCIframe: React.FC<IframeProps> = ({id}) => {
  const {currentUser} = useAuth()

  const location = useLocation()
  // is waystone style in URL
  const isWaystoneStyles =
    location.search.includes('Style=Waystone') ||
    location.search.includes('style=Waystone') ||
    location.search.includes('Style=waystone') ||
    location.search.includes('style=waystone')
  //   state hook
  const {SBLOCChartData, setSBLOCChartData} = useChatbotContext()

  // Listen for messages from the iFrame
  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      // Ensure the message is from a trusted source
      if (event.origin === process.env.REACT_APP_VIVIDLI_URL) {
        if (event.data.type === 'TABLE_DATA') {
          const tableText = event.data.data.join('\n') // Ensure the rows are joined with a newline character
          copyToClipboard(tableText)
        } else {
          setSBLOCChartData((prevState: any) => ({
            ...prevState,
            annualized_interest_rate: event.data.annualized_interest_rate,
            asset_rate_of_return: event.data.asset_rate_of_return,
            borrowed_amount: event.data.borrowed_amount,
            re_invest_rate_of_return: event.data.re_invest_rate_of_return,
            re_invested_amount: event.data.re_invested_amount,
            starting_amount: event.data.starting_amount,
            years_to_grow: event.data.years_to_grow,
          }))
        }
      }
    }

    window.addEventListener('message', handleReceiveMessage)

    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [])
  function copyToClipboard(text: any) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Data copied to clipboard successfully!')
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }
  useEffect(() => {
    sendDataToIframe()
    console.log('*** here ***')
  }, [SBLOCChartData?.sbloc])

  // Function to send data to the iFrame
  const sendDataToIframe = () => {
    const iframe = document.querySelector('iframe')
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        SBLOCChartData?.sbloc,
        `${process.env.REACT_APP_VIVIDLI_URL}`
      )
    }
  }

  return (
    <>
      <iframe
        id={id}
        src={`${process.env.REACT_APP_VIVIDLI_URL}/sblocPage?${
          isWaystoneStyles ? 'Style=Waystone' : ''
        }&&waystone=true&userId=${
          currentUser?.id
        }&isNative=false&&style=${window.location.pathname.substring(1)}`} // substring is to avoid the / in the URL
        title='Web Viewer'
        width='100%'
        style={{height: '100%', border: 'none'}}
        height='100%'
      ></iframe>
    </>
  )
}

export {SBLOCIframe}
