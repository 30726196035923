import {useState, useEffect} from 'react'
import {DataRow} from './core/_model'
import {fetchAllSblocClients, fetchAllUsers, fetchAllUsersForSpecificCompany} from './core/_request'
import DataTableComponent from './components/DataTableComponent'
import Loader from '../../../components/Loader'
import MessageComponent from '../../../components/MessageComponent'
import {useAuth} from '../../../modules/auth'
import axios from 'axios'
interface Props {}

const UsersPage: React.FC<Props> = (props) => {
  const [loading, setloading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [users, setUsers] = useState<DataRow[]>([])
  const [sblocClients, setSblocClients] = useState<any[]>([])
  const {currentUser} = useAuth()
let result:any
try {
  // Check if the input is already an array
  if (typeof currentUser?.registered_companies === 'string') {
    // Try to parse the string
    result = JSON.parse(currentUser?.registered_companies)
  } else if (Array.isArray(currentUser?.registered_companies)) {
    // If currentUser?.registered_companies is already an array, do nothing
    result = currentUser?.registered_companies
  } else {
    throw new Error('Invalid input format')
  }
} catch (error) {
  console.error('Error parsing or processing input:', error)
}

  const handleFetch = async () => {
    setloading(true) // Set loading state to true at the beginning
    try {
      const res =
        currentUser?.user_role === 0
          ? await fetchAllUsers()
          : await fetchAllUsersForSpecificCompany(result[0])

      setUsers(res.data)

      // Only fetch Sbloc Clients if the user_role is 0
      if (currentUser?.user_role === 0) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_VIVIDLI_SBLOC_CLIENTS}`)
          setSblocClients(response.data)
        } catch (innerError) {
          console.error('Error fetching Sbloc Clients:', innerError)
          setIsError(true) // Handle error for Sbloc Clients fetch
        }
      }
    } catch (error: Error | unknown) {
      console.error('Error fetching users:', error)
      setIsError(true) // Handle error for users fetch
    } finally {
      setloading(false) // Ensure loading state is reset
    }
  }

  useEffect(() => {
    handleFetch()
  }, [])

  const handleShowKeywords = (loading: boolean) => {
    switch (loading) {
      case true:
        return <Loader />
      case false:
        return (
          <>
            {isError ? (
              <MessageComponent message='An error occurred. Please try again later' />
            ) : (
              <>
                <DataTableComponent usersData={users} sblocClientsRow={sblocClients} />
              </>
            )}
          </>
        )
      default:
        return null
    }
  }

  return <>{handleShowKeywords(loading)}</>
}

export default UsersPage
