import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header/user/HeaderWrapper'
import {ScrollTop} from './components/scroll-top'
import {reInitMenu} from '../helpers'
import {PageDataProvider} from './core'
import {Content} from './components/content/user/index'
import {FooterWrapper} from './components/footer/user/FooterWrapper'
import {Container} from 'react-bootstrap'
import {ThemeModeProvider} from '../partials'
import {ToolbarWrapper} from './components/toolbar'

const UserLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <>
      <PageDataProvider>
        {/* <HeaderWrapper />
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
        <FooterWrapper /> */}

        <ThemeModeProvider>
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper />

              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  {/* <Container> */}
                  <Content>
                    <Outlet />
                  </Content>
                  {/* </Container> */}
                </div>
                {/* <FooterWrapper /> */}
                <ScrollTop />
              </div>
            </div>
          </div>
        </ThemeModeProvider>
      </PageDataProvider>
    </>
  )
}

export {UserLayout}
