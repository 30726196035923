import MetaTags from '../../../Meta/MetaTags'
import {SBLOCIframe} from '../../modules/components/SBLOCIframe'
import {useParams} from 'react-router-dom'

const SBLOCPage = () => {
  const {companyName} = useParams()
  if (companyName) {
    localStorage.setItem('copmanyInwhichUserGonnaRegister', companyName)
  }
  return (
    <>
      <MetaTags URL={window.location.href} />
      <div style={{height: '90vh'}}>
        <SBLOCIframe id={'myIframe'} />
      </div>
    </>
  )
}

export {SBLOCPage}
