// Submit user request
import axios from 'axios'
const SUBMIT_CHAT_REQUEST = 'chat/'
const FILTER_USER_INPUT = 'chat/filter/'

export function submitChatRequest(
  messages: string,
  threadId: string | null,
  personalizationFormObj: any,
  id: number | undefined,
  IBLOCArray:any[]
) {
  return axios.post(
    SUBMIT_CHAT_REQUEST,
    {
      messages,
      threadId,
      personalizationFormObj,
      id,
      IBLOCArray,
    },
    {
      timeout: 90000,
    }
  )
}

// Filter user request
export function filterUserInput(userInput: string) {
  return axios.post(FILTER_USER_INPUT, {
    userInput,
  })
}
