import axios from 'axios'
const FETCH_NO_OF_WAITLIST_USERS = 'waitlist/count'

// change user password
export function fetchWaitlistCountForSuperAdmin() {
  return axios.get(FETCH_NO_OF_WAITLIST_USERS)
}
export function fetchWaitlistCountForSpecificAdmin(companyName: string) {
  return axios.get(`${FETCH_NO_OF_WAITLIST_USERS}/${companyName}`)
}
